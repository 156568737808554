
import { withRouter, Link, Route, HashRouter as Router, Switch } from 'react-router-dom'
import Bundle from './Bundle.jsx'

let index = function () {
  return <Bundle load={() => import('./pages/index')}>
    {(Com) => <Com />}
  </Bundle>;
};
let trainings1 = function () {
  return <Bundle load={() => import('./pages/essential-certified-coach')}>
    {(Com) => <Com />}
  </Bundle>;
};
let search = function () {
  return <Bundle load={() => import('./pages/search')}>
    {(Com) => <Com />}
  </Bundle>;
};
let contact = function () {
  return <Bundle load={() => import('./pages/contact')}>
    {(Com) => <Com />}
  </Bundle>;
};
let trainings2 = function () {
  return <Bundle load={() => import('./pages/genius-certified-coach')}>
    {(Com) => <Com />}
  </Bundle>;
};
let trainings3 = function () {
  return <Bundle load={() => import('./pages/pro-certified-coach')}>
    {(Com) => <Com />}
  </Bundle>;
};

function App(props) {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={index} exact />
          <Route path="/essential-certified-coach" component={trainings1} exact />
          <Route path="/genius-certified-coach" component={trainings2} exact />
          <Route path="/pro-certified-coach" component={trainings3} exact />
          <Route path="/search" component={search} exact />
          <Route path="/contact" component={contact} exact />
        </Switch>
      </Router>
    </>
  );
}

export default App;